.DSA_stepperCtr .mat-stepper-horizontal-line{
    background:  url('/assets/images/horizontalthreedots.png') no-repeat center left;
    border:0;
    height:4px;
    margin:0;
    flex:0;
    min-width: 24px;
    margin-bottom: 20px;
}  
.DSA_stepperCtr .mat-step-header .mat-step-icon-state-number{
    width:24px;
    height: 24px;
    display: block;
    margin:0 auto;    
    margin-bottom: 16px;
    border-radius: $border-radius-circle;
    font-size: $size-text-16;
    font-family: $font-style-1;
    font-weight: $font-500;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    background: none;
    color: $text-neutral-80;
    /*border:$border-width-1 solid $border-neutral-80; */   
}

.DSA_stepperCtr .mat-step-header .mat-step-icon-state-number:after{
    content: "\e135";
    width: 24px;
    height: 24px;
    font-size: 24px;
    font-family: "DS-iconfont", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    display: inline-block;
    color: $icon-neutral-80;
}
.DSA_stepperCtr .mat-step-header .mat-step-label.mat-step-label-active{
    color:$text-neutral-80;
}  
.DSA_stepperCtr .mat-step-icon{
    width:24px;
    height: 24px;
    display: block;
    margin:0 auto;
    margin-bottom: 16px;
    border-radius: $border-radius-circle;
    font-size: $size-text-16;
    font-family: $font-style-1;
    font-weight: $font-500;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    background: none;
    color: $text-status-success;
    /*border:$border-width-1 solid $border-status-success;*/
}
.DSA_stepperCtr .mat-step-text-label{
    text-align: center;
    font-size: $size-text-14;
    font-family: $font-style-2;
    font-weight: $font-600;
    font-style: normal;
    line-height: 24px;
}
.DSA_stepperCtr .mat-step-label-selected .mat-step-text-label{
    color: $text-primary-80;
}
.DSA_stepperCtr .mat-step-icon-state-done ~ .mat-step-label .mat-step-text-label{
    color: $text-status-success;
}
.DSA_stepperCtr .mat-horizontal-stepper-header{
    display: block;
    min-width: 134px;
    padding: 0;
    padding-top: 8px;
}
.DSA_stepperCtr .mat-horizontal-stepper-header .mat-step-icon{
    margin-right: auto;
}
.DSA_stepperCtr .mat-horizontal-stepper-header:active{
   /* background: $bg-primary-50;*/
}
.DSA_stepperCtr .mat-step-header.cdk-keyboard-focused,
.DSA_stepperCtr .mat-step-header.cdk-program-focused,
.DSA_stepperCtr .mat-step-header:hover{
 background: none;
}

.DSA_stepperCtr .mat-step-label{
    display: block;
    margin-top: 8px;
}
.DSA_stepperCtr .mat-step-header .mat-step-icon-selected{
    /*color: $text-primary-80;
    border: $border-width-1 solid $border-primary-80;*/
}  
.DSA_stepperCtr .mat-step-header .mat-step-icon-selected:after{
    content: "\e135";
    width: 24px;
    height: 24px;
    font-size: 24px;
    font-family: "DS-iconfont", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    display: inline-block;
    color: $icon-primary-80;
  }
  
.DSA_stepperCtr .mat-step-header:hover .mat-step-label,
.DSA_stepperCtr .mat-step-header:hover .mat-step-icon-state-number{
    color:$text-black !important;
}


/*newly added 4Mar2022*/

.mat-step-icon-content span{
    display: none;
}

.DSA_stepperCtr .mat-step-icon-state-done ~ .mat-step-label .mat-step-text-label{
    color:$text-black;
}

.DSA_stepperHorizontal{
    li{
        // padding-bottom: 30px;
        position: relative;
        &:after{
            background:  url('/assets/images/verticalthreedots.png') no-repeat center left;
            width:2px;
            height:14px;
            content:'';
            position: absolute;
            bottom:10px;
            left:7px;
        }
        &:last-child:after{
            background: none;
        }
        a{
            line-height:16px;

            span{
                margin-right: 16px;
                float: left;
            }
        }
    }
    
}

.DSA_stepperVertical{
    li{
        position: relative;
        float: left;
        width: 150px;
        
        &:after{
            background:  url('/assets/images/horizontalthreedots.png') no-repeat center left;
            width:18px;
            height:2px;
            content:'';
            position: absolute;
            top:20px;
            right:-10px;
        }
        &:last-child:after{
            background: none;
        }
        a{
            line-height: 16px;
            text-align: center;
            display: block;
            span{                
                display: block;
                text-align: center;
                margin:0 auto;
                margin-bottom: 16px;
            }
        }
    }
    margin-bottom: 20px;
    
}


  .DSA_stepcomplete span:after,
  .DSA_stepactive span:after,
  .DSA_stepyettostart span:after{
    content: "\e0e2";
    width: 24px;
    height: 24px;
    font-size: 24px;
    font-family: "DS-iconfont", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    display: inline-block;
    color: #007A52;
  }
  .DSA_stepcomplete{
      color:$text-black;
  }
  .DSA_stepcomplete span,
  .DSA_stepactive span,
  .DSA_stepyettostart span{
    width: 24px;
    height: 24px; 
    border-radius:50%;
    display: inline-block;
  }
  .DSA_stepactive span:after{
    content: "\e135";
     color: $icon-primary-80;
  }
  .DSA_stepyettostart span:after{
    content: "\e135";
    color: $icon-neutral-80;
}

.DSA_stepperHorizontal .DSA_stepcomplete span,
.DSA_stepperHorizontal .DSA_stepactive span,
.DSA_stepperHorizontal .DSA_stepyettostart span,
.DSA_stepperHorizontal .DSA_stepcomplete span:after,
.DSA_stepperHorizontal .DSA_stepactive span:after,
.DSA_stepperHorizontal .DSA_stepyettostart span:after{
    width: 16px;
    height: 16px;
    font-size: 16px;
}
  .DSA_stepactive span{
    /*border:1px solid $text-primary-80;*/
  }

  .DSA_stepactive{
    color:$text-primary-70;
}
  .DSA_stepyettostart span{
   /* border:1px solid $border-neutral-80;*/

  }

  .DSA_stepyettostart{
    color:$text-neutral-80;
}