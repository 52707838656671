.DSA_gradedIcons {
    li{
        display: inline-block;
        margin-right: 16px;
    }
}

.DSA_ratingScale{
    .mat-radio-label{
        flex-direction: column;
        justify-content:flex-start; 
        width: 40px;
        text-align: center;
    }
    .mat-radio-label-content{
        white-space: normal;
        margin-top: 8px;
        line-height: 18px;
    }
    .mat-radio-button{
        float: left;
        margin-right: 24px;
    }
}

.DSA_ratingLike{
    li{
        display: inline-block;
        margin-right: 16px;
    }
    .selected i{
        color: $icon-primary-80;
    }
}

.lh-24{
    line-height: $line-height-24;
}