.DSA_breadcrumb{

    a{        
        font-size:$size-button-14;
        font-family: $font-style-1;
        padding: 0;
        span{
            padding: 4px 8px;
            display: inline-block;
            color:$text-primary-80;
            &:hover{
                background: $bg-neutral-40;
                border-radius: $border-radius-big;
            }
        }
    }

    span{
        color: $text-black;
        font-size:$size-text-14;
    }
}
.DSA_breadcrumb a::after{
    content: "\e0af";
    margin: 0 8px 0 0;
    display: inline-block;
    font-family: "DS-iconfont", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-style: normal;
    font-weight: 400!important;
    line-height: 1;
    vertical-align: middle;
    font-size: 20px;
    color: $bg-neutral-60;
}