.DSA_nudge{
    position: fixed;
    bottom: 32px;
    left: 32px;
    width: 530px;
    height: auto; 
    z-index: 1;
    background: #ffffff;
    @extend %large-default-shadow;
    border-radius: $border-radius-big;
    border-left: 4px solid $border-primary-80;
}
.DSA_nudge.nudge_bg{
    background: $bg-primary-50;
}
.DSA_nudgePosition{
    .DSA_nudge{
        bottom: 284px;
    }
    .DSA_nudge.nudge_bg{
        bottom: 32px;
    }
} 
.DSA_nudgeBGRightTop{
    background: url("/assets/images/nudge_bgTop.png") top right no-repeat; 
}
.DSA_nudgeBGRightBottom{
    background: url("/assets/images/nudge_bgBottom.png") bottom right no-repeat;       
    padding: 16px 48px;  
}
.DSA_nudgeHeader{
    padding: 0;
    line-height: 24px;
}
.DSA_nudgeContent{
    margin: 16px 0 0;
}
.DSA_nudgeTitle{
    color: $text-black;
    line-height: 24px;
}
.DSA_nudgeFooter{    
    margin: 16px 0 0;
}

.DSA_wb_margin159{
    margin: 0 0 0 175px;
}
.DSA_wb_margin80{
    margin: 0 0 0 80px;
}
.DSA_nudgeFooter.DSA_wb_margin80{
    margin: 16px 0 0 80px;
}
.DSA_nudgeFooter.DSA_wb_margin159{
    margin: 16px 0 0 175px;
}
.survey_ul{
    margin: 0;
    display: inline-block;
}
.survey_ul li{
    margin: 0 24px 0 0;
    float: left;
    width: 32px;
    height: 32px;
}
.survey_ul li a{
    width: 32px;
    height: 32px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
.survey_ul li.active a{
    i.smiley5-icon{
        color: $icon-status-error;
    }
    i.smiley3-icon{
        color: $icon-status-warning;
    } 
    i.smiley2-icon{
        color: $icon-yellow;
    } 
    i.smiley1-icon{
        color: $icon-status-success;
    } 
}
.DSA_nudge_video{
    width: auto;
    float: left;
    margin: 0;
}
@media screen and (max-width:'576px'){
    .DSA_nudge{
        width: 100%;
        left: 0;
    }
    .DSA_nudge_video{
        width: 100%;
        float: none;
        margin: 0 0 16px;
    }
    .DSA_wb_margin159{
        margin: 0;
    }
    .DSA_nudgeFooter.DSA_wb_margin159, .DSA_nudgeFooter.DSA_wb_margin80{
        margin: 16px 0 0;
    }
    .DSA_nudgePosition .DSA_nudge{
        bottom: 452px;
    }
}