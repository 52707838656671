/* Custom Comments - Start */
.DSA_wb_commentTxtCtr{
    margin-left:56px;
}
.DSA_wb_commentTextareaCtr{
    background: $bg-neutral-30;
    position: relative;
    border-bottom: 1px solid $bg-neutral-50;
}
.DSA_wb_commentTextarea{
    margin-right: 30px;
    padding:8px 0;
}
.DSA_wb_commentTextarea textarea{
    width:100%;
    height:22px;
    border:0;
    background: none;
    outline: none;
}
.DSA_socialinteraction >a, .DSA_socialinteraction >div  {
    float:left;
    margin-right: 32px;
}
.DSA_socialinteraction >a span, .DSA_socialinteraction >div span{
    vertical-align: middle;
    margin-left: 8px;
    display: inline-block;
    padding-top: 4px;
    padding-bottom: 4px;
}

/* Custom Comments - End */