.DSA_wb_card-widget-02.DSA_wb_card-withgreybg{
    width: 200px;
    height: 304px;
    background: $bg-neutral-40;
    padding: 0 16px 8px;
    h5{
        color: $text-white;
        a.web-Greywhite{
            text-decoration: underline;
            text-transform: uppercase;
            &:hover, &:focus{
            color: $text-white;
            }
        }
    }
    .DSA_matCard-imageCtr.DSA_matCard-imageTop{
        height: 200px;
        margin: 0 -16px 16px;
        overflow: hidden;
        border-top-left-radius: $border-radius-extrabig;
        border-top-right-radius: $border-radius-extrabig;
        .mat-card-image{
            width: auto;
            position: absolute;
            left: 50%;
            @include transform(translate(-50%, 0px));
        }
    }
    .DSA_absTop{
        width: calc(100% - 32px);
        top: auto;
        bottom: 0;
        left: 16px;
        .mat-card-subtitle.DSA_wb_h3Header{
            margin: 16px 0 12px;
        } 
    }  
    .DSA_imgOverlay{
        width: 100%;
        height: 82px;
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        /* border-top-left-radius: $border-radius-extrabig;
        border-top-right-radius: $border-radius-extrabig; */
        background: $bg-black;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        /*background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.30) 33%, rgba(0,0,0,0.25) 35%, rgba(0,0,0,0) 100%);*/
    }   
    .DSA_absChip{
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .DSA_wb_card-footer{
        width: 100%;
        height: 24px;
        margin: 8px 0 0;
        visibility: hidden;
        opacity: 0;
        transition:all 0.2s ease 0.2s;    
    }
    .icon-withText{
        line-height: 24px;
    }
    .DSA_wb_caption.text-animate{
        transition:all 0.2s ease 0.2s; 
        line-height: 16px;
        display: inline-block;
        height: 48px;
    }
    &:hover{
        background: $bg-white;
        .DSA_wb_caption.text-animate{
            color: $text-primary-80;
            margin-top: -4px;
        } 
        .DSA_wb_card-footer{
            visibility: visible;
            opacity: 1;
        }
    }  
    .DSA_wb_card-footer-visible{
        visibility: visible;
        opacity: 1;
    }
}

.DSA_wb_card-widget-02.DSA_wb_card-withgreybg.DSA_wb_card-gallery{
    width: 275px;
    height: 378px;
    background: $bg-neutral-40;
    padding: 0 16px 8px;
    h5{
        color: $text-white;
        a.web-Greywhite{
            text-decoration: underline;
            text-transform: uppercase;
            &:hover, &:focus{
            color: $text-white;
            }
        }
    }
    .DSA_matCard-imageCtr.DSA_matCard-imageTop{
        height: 275px;
        margin: 0 -16px 16px;
        overflow: hidden;
        border-top-left-radius: $border-radius-extrabig;
        border-top-right-radius: $border-radius-extrabig;
        .mat-card-image{
            width: auto;
            position: absolute;
            left: 50%;
            @include transform(translate(-50%, 0px));
        }
    }
    .DSA_absTop{
        width: calc(100% - 32px);
        top: auto;
        bottom: 0;
        left: 16px;
        .mat-card-subtitle.DSA_wb_h3Header{
            margin: 16px 0 12px;
        } 
    }  
    .DSA_imgOverlay{
        width: 100%;
        height: 82px;
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        /* border-top-left-radius: $border-radius-extrabig;
        border-top-right-radius: $border-radius-extrabig; */
        background: $bg-black;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        /*background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75));
        background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.30) 33%, rgba(0,0,0,0.25) 35%, rgba(0,0,0,0) 100%);*/
    }   
    .DSA_absChip{
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .DSA_wb_card-footer{
        width: 100%;
        height: 24px;
        margin: 8px 0 0;
        visibility: hidden;
        opacity: 0;
        transition:all 0.2s ease 0.2s;    
    }
    .icon-withText{
        line-height: 24px;
    }
    .DSA_wb_caption.text-animate{
        transition:all 0.2s ease 0.2s; 
        line-height: 16px;
        display: inline-block;
        height: 48px;
    }
    &:hover{
        background: $bg-white;
        .DSA_wb_caption.text-animate{
            color: $text-primary-80;
            margin-top: -4px;
        } 
        .DSA_wb_card-footer{
            visibility: visible;
            opacity: 1;
        }
    }  
    .DSA_wb_card-footer-visible{
        visibility: visible;
        opacity: 1;
    }
    .DSA_wb_normalListItem{
        li > a{
            padding: 8px 0 0;
            img{
                width: auto;
            }
        }
        li > a.noHover:hover{
            background: none;
        }
    } 
}