/*progress bar*/
.DSA_customprogress .mat-progress-bar{
    height: 9px !important;
    border-radius: 5px;
}
.DSA_customprogress .mat-progress-bar-background{
    fill:$bg-neutral-50;
}
.DSA_customprogress .mat-progress-bar-buffer{
    background-color:$bg-neutral-50;
}
.DSA_customprogress .mat-progress-bar-fill::after{
    background-color:$bg-primary-80;
    border-radius: 5px;
}
/*progress bar*/