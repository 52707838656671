/*tooltip customization Start*/
.DSA_tooltip.mat-tooltip {
    background:$bg-neutral-40;
    border-radius: $border-radius-small;
    @extend %default-shadow;
    color:$text-black !important;
    padding:8px !important;
    display: inline-block;
    font-size: $size-text-12;
}
/*tooltip customization End*/