/* List Items - Start */
@import "web-list-common.scss";
@import "web-list-secondary.scss";
@import "web-list-topmenu.scss";

.DSA_wb_normalListItem {
  list-style: none;
  padding:0px;
  margin:0; 
  li:after{
    display: block;
    clear: both;
    content: "";
  }   
  li.active{
    background: $bg-primary-50;
  }
  li > .noHover{
    padding: 0;
  }
  li > .noHover:hover{
    background: none;    
  }
} 
.DSA_wb_normalListItem >li>div.clearfix{
    width: 100%;
    &:after{
        display: none;
      }  

}  
.DSA_wb_normalListItem > li{
  padding: 0px;
  margin: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d; 
  position: relative;
} 
.DSA_wb_normalListItem > li > a, .DSA_wb_normalListItem > li > span{
  padding: 12px 16px;
  border: none;
  display: block;
  position: relative;
  -webkit-transition: all 280ms linear;
  -moz-transition: all 280ms linear;
  -o-transition: all 280ms linear;
  -ms-transition: all 280ms linear;
  transition: all 280ms linear;
  .DSA_wb_lh_32{
    line-height: 32px;
  } 
  .DSA_wb_lh_56{
    line-height: 56px;
  }
  &.DSA_wb_list-actions{
    overflow: hidden;
    .text-animate{
      transition:all 0.2s ease 0.2s; 
      margin-top: 4px;    
    }
    .list-actions-hover{      
      position: absolute;
      left: 0;
      right: 0;
      padding: 0px 8px;
      visibility: hidden;
      opacity: 0;
      transition:all 0.2s ease 0.2s;       
    }
  }
  
}


.DSA_wb_normalListItem > li > a:hover, .DSA_wb_normalListItem > li > span:hover{
  text-decoration: none;  
  background-color: $bg-neutral-40;
  border-radius: $border-radius-big;  
  &.DSA_wb_list-actions{
    padding-bottom: 36px; 
    .text-animate{
      margin-top: -4px;
      margin-bottom: 4px;
    }   
    .list-actions-hover{     
      opacity: 1;
      visibility: visible;
    }
  }
}

.DSA_wb_normalListItem > li > a:active, .DSA_wb_normalListItem > li > span:active{
  background-color: $bg-neutral-60;  
}
.DSA_wb_listrounded  li > a:hover,.DSA_wb_listrounded li > a:active{
  border-radius: $border-radius-big;
  box-shadow: none !important;
}
.DSA_wb_withIcon li a:active i::before{
  color:$text-black;
}
.DSA_wb_normalListItem.DSA_wb_largeList > li.active > a{
  background-color: $bg-primary-50;
 /* border-left: 4px solid $border-primary-80;  */
}

.DSA_wb_normalListItem.DSA_wb_largeList > li.active > a::after{
    content: '';
    width: 4px;
    display: block;
    background: $bg-primary-80;
    border-radius: $border-radius-small;
    position: absolute;
    bottom: 0px;
    top:0;
    left:0px;
    z-index: 2;
}
/* .DSA_wb_normalListItem li.active > a, .DSA_wb_normalListItem li.active > span{
  background-color: $bg-neutral-50;
} */
  
/* .DSA_wb_normalListItemShadow{
  li{
    border: none;
    display: block;
    position: relative;
    transition: all 280ms linear;
    div{      
      border-bottom: 1px solid #EFEFEF;
      &.topbor{
        border-top: 1px solid #EFEFEF;
      }    
      a{
        padding:24px;      
        border: none;
        display: block;
        position: relative;
        -webkit-transition: all 280ms linear;
        transition: all 280ms linear;
        .showhover{
          display: none;
        }
      }
      a:hover{
        background: none;
        @extend %hover-shadow;
        color:$text-primary-80;
      }
      a:hover .showhover{
        display: inline-block;
      }
    }
  }  
} */
.DSA_wb_normalListItem.DSA_wb_listWithRadius{
  li > span{
    padding: 16px 16px 8px;
    .list-animate{
      transition:all 0.2s ease 0.2s;    
    }      
    .DSA_vertical-RightCenter{
      .icon{
        opacity: 0;
      }
    }
    a.link_xs{
      font-size: $size-text-12;
      font-style: normal;
      color: $text-primary-80;      
      margin: 4px 0 0;
      opacity: 0;
      transition:all 0.2s ease 0.2s; 
    }
    .info_xs{
      font-size: $size-text-10;
      font-style: normal;
      color: $text-primary-80;
      display: block;
      margin: 8px 0 0;
      text-transform: uppercase;
      opacity: 0;
      transition:all 0.2s ease 0.2s; 
    }
    &:hover{         
      background:  $bg-primary-50;
      border-radius: $border-radius-big;
      @extend %default-shadow;          
      .list-animate{
        margin-top: -4px;
        margin-bottom: 4px; 
      }      
      a.link_xs, .info_xs{
        opacity: 1;
      }  
      .DSA_vertical-RightCenter{
        .icon{
          opacity: 1;
        }
      }    
    }    
  }    
}

.DSA_wb_largeList > li>a:hover, .DSA_wb_largeList > li>span:hover {
     border-radius:0px; 
}
.DSA_wb_largeList .DSA_wb_caption .location-icon,
.DSA_wb_listItemSingle .DSA_wb_caption .location-icon{
    vertical-align: middle;

}
.DSA_imageradius-big{
  border-radius: $border-radius-big;
} 
.DSA_imageradius-extrabig{
  border-radius: $border-radius-extrabig;
} 
/* List Items - End */

/* List variations - Start */
.listImgCont{
  position: relative;
  overflow: hidden;
  &.size-56{
    width: 56px;
    height: 56px;    
  }
  &.size-64{
    width: 64px;
    height: 64px;    
  }
  &.size-80{
    width: 80px;
    height: 80px;
  }
  &.size-144{
    width: 144px;
    height: 144px;
  }
  img{
    position: absolute;
    width: auto;
    height: 100%;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    /*border-radius: $border-radius-extrabig;*/
  }
}
.DSA_wb_marginicon144{
  margin: 0 0 0 160px;
  display: block;
}

/* List variations - End */
.DSA_wb_normalListItem.DSA_wb_largeList > li > a{
  padding: 24px;
  background-color: $bg-neutral-30;
  /*border-left: 4px solid transparent;*/
}
.DSA_wb_normalListItem.DSA_wb_largeList.noBGColor > li > a{
    background-color: transparent;
  }



.DSA_multilist li{
 padding:4px 8px;
}
.DSAinneraccordionIcon{
    position: absolute;
    left:-16px;
    top:0px;
}
.DSAinneraccordionText{
    display: inline-block;
    margin-left: 4px;
    color:$text-neutral-90;
}