/*overflow menu customization Start*/
.DSA_dropDown.dropdown-menu{
    @extend %default-shadow ;
    border:0;
    border-radius: $border-radius-big;
}
.DSA_dropDown.dropdown-menu .DSA_wb_listItemSingle{
    margin: 8px 0;
}

.DSA_dropdown-iconOnly::after{
    display: none !important;
  }
  .dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
  }
/*overflow menu customization End*/