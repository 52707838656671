.sp2_label-value{
    font: 400 14px/20px Roboto,"Helvetica Neue",sans-serif;
}
.pull-right{
    // text-align: right;
    float: right;
}
.pull-left{
    // text-align: left;
    float: right;
}
.panel-padding{
    padding: 20px;
}
.sp2_form-label {
    text-transform: none;
}
.sp2_form-label {
    font-size: 12px;
    font-weight: normal;
    color: #898989;
    display: block;
    padding-left: 0;
    margin-bottom: 0;
    
}
.sp2_headh4 {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px;
    color: #8626C3;
}
.sm-txt {
    font-size: 12px;
}

.bullet-list {
    
    
    margin-bottom: 10px;
    /* color: black; */
    font-size: 14px;
    margin-left: 20px;
}

.bullet-list li {
    display: list-item;
    list-style: disc;
    margin-bottom: 10px;
    color:rgb(0, 0, 0);
}
.inner-content {
    padding: 20px;
    /* background-color: rgba(255, 255, 255, 0.726); */
}


.inboxview {
    margin: 0 0 30px !important;
}

.headingMargin{
    margin: 24px 0px 24px 0px;
}
.alert-error {
    color: #000;
    font-size: 14px;
    background: url(/assets/images/error.png) no-repeat 10px center #ebccd1;
    border-radius: 5px;
    padding-left: 40px;
    margin-bottom: 0px !important;
}
.width100Per{
    width: 100% !important;
}

.breadcrumb {
    background: none;
    font-size: 0.8em;
    margin: 0;
         display: flex;
        flex-wrap: wrap;
        padding: 0px 0 0px 0px;
   
        list-style: none;
       
    // a,
    // span {
    //   color: darkgrey;
    // }
    // a,
    // span {
    //   color: darkgrey;
    // }
    a:hover,
    span:hover {
      color: dimgrey;
      text-decoration: none;
    }
    li {
        list-style: none;
        float: left;
        margin-right: 6px;
        cursor: pointer;
      
      a,
    span {
        top: 0px;
        left: 0px;
        width: 100px;
        height: 19px;
        text-align: left;
        
        letter-spacing: 0px;
        color: #8626C3;
        outline: none;
    }
      
    }
    li:last-child {
        cursor: default;
      margin-right: 20px;
      a,
    span {
        top: 0px;
        left: 0px;
        width: 100px;
        height: 19px;
        text-align: left;
     
        letter-spacing: 0px;
        color: #000000;
    }
    }
    li::after {
      content: "->";
      color: darkgrey;
      padding: 0px 0px 0px 4px;
    }
    li:last-child::after {
      content: "";
    }
  }