.DSA_wb_card-widget.DSA_card-square{
    min-width: 100px;
    max-width: 100px;
    float: left;     
}
.DSA_wb_card-widget.DSA_card-square.DSA_square-lg{
    min-width: 172px;
    max-width: 172px;
}
.DSA_wb_title{
    font-family: $font-style-2;
    font-size: $size-hd-48;
    font-weight: $font-600;
    font-style: normal;
    color: $text-black;
}