.icon-infyme{
    background: url('../assets/images/webIcons/default_icons/infyMe.png') no-repeat;
}
.icon-work{
    background: url('../assets/images/webIcons/default_icons/work.png') no-repeat;
}
.icon-startthick{
    background: url('../assets/images/webIcons/default_icons/star.png') no-repeat;
}

.icon-Hamburger.lightPurple{
    background: url('../assets/images/webIcons/lightpurple_icons/AppMenu.png') no-repeat;
}

.icon-Hamburger.purple{
    background: url('../assets/images/webIcons/purple_icons/AppMenu.png') no-repeat;
}

.icon-web-Dashboard{
    background: url('../assets/images/webIcons/default_icons/ic_Dashboard.png') no-repeat;
}

.icon-web-Candidates{
    background: url('../assets/images/webIcons/default_icons/ic_Profile.png') no-repeat;
}
.icon-web-Jobs.purple{
    background: url('../assets/images/webIcons/purple_icons/ic_Activity.png') no-repeat;
}

.icon-web-Events{
    background: url('../assets/images/webIcons/default_icons/ic_Schedule.png') no-repeat;
}

.icon-Appmenu.white{
    background: url('../assets/images/icons/white_icons/icon-menu.png') no-repeat;
}
.www-icon{
    background: url('../assets/images/webIcons/default_icons/icon-www.png') no-repeat;
}
.www-icon.white{
    background: url('../assets/images/webIcons/white_icons/icon-www.png') no-repeat;
}
.www-icon.purple{
    background: url('../assets/images/webIcons/purple_icons/icon-www.png') no-repeat;
}
.www-icon.dark-purple{
    background: url('../assets/images/webIcons/darkpurple_icons/icon-www.png') no-repeat;
}
.star-icon.dark-purple{
    background: url('../assets/images/webIcons/darkpurple_icons/icon-star.png') no-repeat;
}

.icon-badge-big{
    background: url('../assets/images/webIcons/default_icons/icon-badge-big.png') no-repeat;
}
.icon-banking-big{
    background: url('../assets/images/webIcons/default_icons/icon-banking-big.png') no-repeat;
}
.icon-creditcard-big{
    background: url('../assets/images/webIcons/default_icons/icon-creditcard1-big.png') no-repeat;
}


.icon-web-sucesstick.green{
    background: url('../assets/images/webIcons/green_icons/icon-success-big.png') no-repeat;
}
.icon-address-big.green{
    background: url('../assets/images/webIcons/green_icons/icon-address-big.png') no-repeat;
}
.icon-web-tick{
    background: url('../assets/images/webIcons/icon-tick.png') no-repeat;
}
.icon-web-close{
    background: url('../assets/images/webIcons/icon-close.png') no-repeat;
}


.icon-success-tick.green{
    background: url('../assets/images/webIcons/green_icons/icon-tick-dark.png') no-repeat;
}
.prev-icon-01{
    background: url('../assets/images/caurosel/icon-prev-01.png') no-repeat center; 
}
.next-icon-01{
    background: url('../assets/images/caurosel/icon-next-01.png') no-repeat center; 
}
// .icon-Hamburger.white{
//     background: url('../assets/images/webIcons/white_icons/AppMenu.png') no-repeat;
// }

// .icon-Hamburger.purple{
//     background: url('../assets/images/webIcons/purple_icons/AppMenu.png') no-repeat;
// }


/*.icon-Appmenu.purple{
    background: url('../assets/images/webIcons/purple_icons/hamburgerMenu.png') no-repeat;
}*/