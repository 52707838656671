.DSA_wb_facePile{
    li{
        margin: 0 16px 0 0;
        float: left;
        a{
            display: inline-block;
        }
        a.greyIcon-Circle{
            div{
                vertical-align: middle;
                display: inline-block;
            }
        }
    }
}


.DSA_wb_facePile32{
    li{
        margin: 0 4px 0 0;
        a{
            img{
                width: 32px;
                height: 32px;
            }
            &.greyIcon-Circle{
                width: 32px;
                height: 32px;
                .DSA_wb_mainBodyTxt2{
                    padding: 2px;
                }
            }
        }

    }    
}

.DSA_wb_facePile40 {
    li{
        margin: 0 8px 0 0;
        a{
            img{
                width: 40px;
                height: 40px;
            }
            &.greyIcon-Circle{
                width: 40px;
                height: 40px;
                .DSA_wb_mainBodyTxt{
                    padding: 3px;
                }
            }
        }
    }    
}

.DSA_avatarList{
    li{
        float: left;
    }
}

.DSA_avatar{
    background: $bg-primary-60;
    color:$text-primary-80;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;   
    font-weight: $font-400;   
    font-family: $font-style-1;
    font-style: normal;
    float: left;
    
    &.DSA_avatar_24{
        width: 24px;
        height: 24px;
        font-size: $size-text-12;
        line-height: 24px;
    }
    
    &.DSA_avatar_32{
        width: 32px;
        height: 32px;
        font-size: $size-text-14;
        line-height: 32px;
    }

    &.DSA_avatar_40{
        width: 40px;
        height: 40px;
        font-size: $size-text-16;
        line-height: 40px;
    }

    &.DSA_avatar_48{
        width: 48px;
        height: 48px;
        font-size: $size-hd-18;
        line-height: 48px;
    }

    &.DSA_avatar_56{
        width: 56px;
        height: 56px;
        font-size: $size-hd-24;
        line-height: 56px;
    }
}