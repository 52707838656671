  /*icon round*/
  .avatarImageCircle {
    @include icon-circle;
    padding-top:0;
}
  
  .greyIcon-Circle{
    @include icon-circle;
    background: $grey-lighter;
  }
  
  .whiteIcon-Circle{
    @include icon-circle;
    @extend %default-shadow;
    background:#fff;
  }
  .whiteIcon-Circle-small{
    @include icon-circle-small;
    @extend %default-shadow;
    background:#fff;
  } 
  .whiteIconNoshadow-Circle{
    @include icon-circle;
    background:#fff;
  }
  .badgeIcon-Circle{
    @include icon-circle;
    @extend %default-shadow;
      width: 56px;
      height: 56px;
      background:#fff;
      padding-top: 10px;
      position: relative;

      .notifications{
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top:0px;
          right:0px;
          font-size: 10px;
          position: absolute;
          display: block;
      }
  }
  .disabledIcon-Circle{
    @include icon-circle;
    @extend %default-shadow;
    background:#fff;
  }

  
  .disabledIcon-Circle i,.disabledIcon {
    opacity: 0.2;
}
  .purpleIcon-Circle{
    @include icon-circle;
    background: $color-lighter;
  }
  .purpleIconActive-Circle{
    @include icon-circle;
    @extend %active-shadow;
    background: $color-lighter;
  }
  .tabIcon-Circle{
    @include icon-circle;
    background: transparent;
    border: 1px solid $grey-white;
  }
  
  
  
  /*logo*/  
  
  .infy-logo-light{
    background: url('../assets/images/icons/default_icons/Infosys_logo_natural.png');
    width: 66px;
    height: 24px;
  }
  
  .infy-me-logo{
    background: url('../assets/images/icons/default_icons/Infy-launchpad-small.png');
    width: 95px;
    height: 40px;
  }

  .infy-logo-light.white{
    background: url('../assets/images/icons/white_icons/Infosys_logo_natural.png');
    width: 66px;
    height: 24px;
  }
  
  .infy-me-logo.white{
    background: url('../assets/images/icons/white_icons/Infy-launchpad-small.png');
    width: 95px;
    height: 40px;
  }
  
  

/* Icon Styles Begin */
.icon-list{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .icon-list li{
  display: inline-block;
  padding:1rem;
  text-align: center;
  background:$grey-lightwhite;
  margin: 0.5rem;
  min-height: 130px;
  vertical-align: top;
  
  
  }
  .w-grid-8-l {
    width: calc(100% / 7);
  }
  
  .icon{
    display: block;
    background-repeat: no-repeat!important;
    margin: auto;
    color: #7e7e7e;
  
  }
  .iconinline{
    display: inline-block;
  }
  
  i{
    font-style: normal;
    
  }
  /*icon position*/
  .icon.left{
    background-position: left center
  }
  
  .icon.right{
    background-position: right center
  }
  .icon.left8{
    background-position: 8px 50%;
  }
  
  .icon.right8{
    background-position: right 8px center;
  }
  .icon.left16{
    background-position: 16px 50%;
  }
  
  .icon.right16{
    background-position: right 16px center;
  }
  .icon.center{
    background-position: center center
  }
  .botRight{
    background-position: right 10px bottom 6px;
  }
  .x-16{
    width: 16px;
    height: 16px;
  }
  
  .x-24{
    width: 24px;
    height: 24px;
  
  }
  .x-35{
    width: 35px;
    height: 35px;
  
  }
  .x-40{
    width: 40px;
    height: 40px;
  
  }
  .x-56{
    width: 56px;
    height: 56px;
  
  }
  code{
    display: block;
    color: $black-color!important;
    text-align: left;
    margin-top: 10px;
  }
  
  ul.icon-list.white-bg li {
    background: $grey-dark;
  
    code{
      color: $white!important;
    }
  }
  
  
  
  .icon-select-mini{
    background-image:url('../assets/images/icons/default_icons/icon-select-mini.png'); 
  }
  .icon-close-mini{
    background-image:url('../assets/images/icons/default_icons/icon-close-mini.png'); 
  }
  .icon-tick-dark{
    background-image:url('../assets/images/icons/default_icons/icon-tick-dark.png'); 
  }
  

  .icon-avatar{
    background-image:url('../assets/images/icons/default_icons/avatar-mini.png'); 
  }
  .icon-process-complete.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-process-complete-big.png'); 
  }
  
  .chat-icon{
    background-image:url('../assets/images/icons/default_icons/icon-chat.png'); 

  }
  .clapp-icon{
    background-image:url('../assets/images/icons/default_icons/icon-clapp.png'); 
  
  }
  .hand-icon{
    background-image:url('../assets/images/icons/default_icons/icon-hand.png'); 
  
  }
  .info-icon{
    background-image:url('../assets/images/icons/default_icons/icon-info-small.png'); 
  
  }
  .smiley-icon{
   background-image:url('../assets/images/icons/default_icons/icon-smileye.png');
  }
  
  .menu-icon{
    background-image: url('../assets/images/icons/default_icons/icon-menu.png');
  }
  
  .back-icon{
    background-image: url('../assets/images/icons/default_icons/icon-back.png');
  }
  .callback-icon{
    background-image: url('../assets/images/icons/default_icons/icon-callback.png');
  }
  
  .search-icon{
    background-image: url('../assets/images/icons/default_icons/icon_search.png');
  }
  
  .close-icon{
    background-image: url('../assets/images/icons/default_icons/icon-close.png');
  }
  
  .faq-info-icon{
    background-image: url('../assets/images/icons/default_icons/icon-faq-info.png');
  
  }
  .alert-success-icon{
    background-image: url('../assets/images/icons/default_icons/icon-alert-success.png');
  
  }
  
  .alert-error-icon{
    background-image: url('../assets/images/icons/default_icons/icon-alert-error.png');
  
  }
  
  .filter-icon{
    background-image: url('../assets/images/icons/default_icons/icon-filter.png');
  
  }
  
  .copy-icon{
    background-image: url('../assets/images/icons/default_icons/icon-copy.png');
  
  }
  
  .location-icon{
    background-image: url('../assets/images/icons/default_icons/icon-location.png');
  
  }
  
  .star-icon{
    background-image: url('../assets/images/icons/default_icons/icon-star.png');
  
  }
  
  .previous-icon{
    background-image: url('../assets/images/icons/default_icons/icon-previous.png');
  
  }
  
  .next-icon{
    background-image: url('../assets/images/icons/default_icons/icon-next.png');
  
  }
  .down-arw-icon{
    background-image: url('../assets/images/icons/default_icons/icon-expand-arrow.png');
  
  }icon-relocation
  
  .up-arw-icon{
    background-image: url('../assets/images/icons/default_icons/icon-collapse-arrow.png');
  
  }
  
  .edit-icon{
    background-image: url('../assets/images/icons/default_icons/icon-edit.png');
  
  }
    .delete-sm-icon{
      background-image: url('../assets/images/icons/default_icons/icon-delete-mini.png');
    
    }
  
    .calendar-icon{
      background-image: url('../assets/images/icons/default_icons/icon-calendar.png');
    
    }
  
    .attachment-icon{
      background-image: url('../assets/images/icons/default_icons/icon-attachment.png');
    
    }
  
  
  
  
  
  
  
  
  
    .comment-icon{
      background-image: url('../assets/images/icons/default_icons/icon-comment.png');
    
    }
  
    .like-icon{
      background-image: url('../assets/images/icons/default_icons/icon-like.png');
    
    }
  
    .link-arw-icon{
      background-image: url('../assets/images/icons/default_icons/icon-link-arrow.png');
    
    }
  
    .home-icon{
      background-image: url('../assets/images/icons/default_icons/icon-home.png');
    
    }
  
    .notification-icon{
      background-image: url('../assets/images/icons/default_icons/icon-notification.png');
    
    }
  
    .profile-icon{
      background-image: url('../assets/images/icons/default_icons/icon-profile.png');
    
    }
  
    .services-icon{
      background-image: url('../assets/images/icons/default_icons/icon-services.png');
    
    }
  
     
  
    .v-menu-icon{
      background-image: url('../assets/images/icons/default_icons/icon-v-menu.png');
      width: 16px;
    
    }
  
    .mail-icon{
      background-image: url('../assets/images/icons/default_icons/icon-mail.png');
    
    }
  
    .call-icon{
      background-image: url('../assets/images/icons/default_icons/icon-call.png');
    
    }
  
    .download-icon{
      background-image: url('../assets/images/icons/default_icons/icon-download.png');
    
    }
  
    .upload-icon{
      background-image: url('../assets/images/icons/default_icons/icon-upload.png');
    
    }
  
    .inprogress-icon{
      background-image: url('../assets/images/icons/default_icons/icon-inprogress.png');
    
    }
  
  
  
    .mic-icon{
      background-image: url('../assets/images/icons/default_icons/icon-mic.png');
    
    }
    .hide-icon{
      background-image: url('../assets/images/icons/default_icons/icon-hide.png');
    
    }
  
    .show-icon{
      background-image: url('../assets/images/icons/default_icons/icon-show.png');
    
    }
     
  
    .dropdown-icon{
      background-image: url('../assets/images/icons/default_icons/icon-dropdown.png');
    
    }
  
    .thumb-up-icon{
      background-image: url('../assets/images/icons/default_icons/icon-thumb-up.png');
    
    }
    .graph-icon{
      background-image: url('../assets/images/icons/default_icons/icon-graph.png');
    
    }
  
    .announcement-icon{
      background-image: url('../assets/images/icons/default_icons/icon-announcement.png');
    
    }
    .group-icon{
      background-image: url('../assets/images/icons/default_icons/icon-group.png');
    
    }
  
  
    .id-badge-icon{
      background-image: url('../assets/images/icons/default_icons/icon-id-badge.png');
    
    }
  
    .emergency-icon{
      background-image: url('../assets/images/icons/default_icons/icon-emergency.png');
    
    }
  
    .personal-icon{
      background-image: url('../assets/images/icons/default_icons/icon-personal.png');
    
    }
    .personal-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-personal.png');
    
    }
  
    .address-icon{
      background-image: url('../assets/images/icons/default_icons/icon-address.png');
    
    }
  
    .i9-icon{
      background-image: url('../assets/images/icons/default_icons/icon-i9.png');
    
    }
  
    .ssn-icon{
      background-image: url('../assets/images/icons/default_icons/icon-ssn.png');
    
    }
  
    .bank-icon{
      background-image: url('../assets/images/icons/default_icons/icon-bank.png');
    
    }
  
    .corp-cc-icon{
      background-image: url('../assets/images/icons/default_icons/icon-corporate-cc.png');
    
    }
  
    
    .relocation-icon{
      background-image: url('../assets/images/icons/default_icons/icon-relocation.png');
    
    }
  
    .accomodation-icon{
      background-image: url('../assets/images/icons/default_icons/icon-accomodation.png');
    
    }
  
    .food-icon{
      background-image: url('../assets/images/icons/default_icons/icon-food.png');
    
    }
  
  
    .form-icon{
      background-image: url('../assets/images/icons/default_icons/icon-form.png');
    
    }
  
    .payroll-icon{
      background-image: url('../assets/images/icons/default_icons/icon-payroll.png');
    
    }
  
    .insurance-icon{
      background-image: url('../assets/images/icons/default_icons/icon-insurance.png');
    
    }
  
     
  
    .bills-icon{
      background-image: url('../assets/images/icons/default_icons/icon-bills.png');
    
    }
    .w4-icon{
      background-image: url('../assets/images/icons/default_icons/icon-w4.png');
    
    }
  
    .document-icon{
      background-image: url('../assets/images/icons/default_icons/icon-document.png');
    
    }
  
    
  
    .nomination-icon{
      background-image: url('../assets/images/icons/default_icons/icon-nomination.png');
    
    }
  
    
    .pan-icon{
      background-image: url('../assets/images/icons/default_icons/icon-pan.png');
    
    }
  
    .nsr-icon{
      background-image: url('../assets/images/icons/default_icons/icon-nsr.png');
    
    }
  
    .passport-icon{
      background-image: url('../assets/images/icons/default_icons/icon-passport.png');
    
    }
  
    .education-icon{
      background-image: url('../assets/images/icons/default_icons/icon-education.png');
    
    }
  
    .work-exp-icon{
      background-image: url('../assets/images/icons/default_icons/icon-work-exp.png');
    
    }
  
    .achivements-icon{
      background-image: url('../assets/images/icons/default_icons/icon-achivements.png');
    
    }
  
    .statutory-icon{
      background-image: url('../assets/images/icons/default_icons/icon-statutory.png');
    
    }
  
    .authentication-icon{
      background-image: url('../assets/images/icons/default_icons/icon-authentication.png');
    
    }
  
    .family-icon{
      background-image: url('../assets/images/icons/default_icons/icon-family.png');
    
    }
  
    .disability-icon{
      background-image: url('../assets/images/icons/default_icons/icon-disability.png');
    
    }
    
    .video-icon{
      background-image: url('../assets/images/icons/default_icons/icon-video.png');
    
    }
  
    .onboarding-icon{
      background-image: url('../assets/images/icons/default_icons/icon-onboarding.png');
    
    }
  
    .aadhar-icon{
      background-image: url('../assets/images/icons/default_icons/icon-aadhar.png');
    
    }
  
    .medical-icon{
      background-image: url('../assets/images/icons/default_icons/icon-medical.png');
    
    }
  
    .team-icon{
      background-image: url('../assets/images/icons/default_icons/icon-team.png');
    
    }
  
    .gallery-icon{
      background-image: url('../assets/images/icons/default_icons/icon-gallery.png');
    
    }
  
    .camera-icon{
      background-image: url('../assets/images/icons/default_icons/icon-camera.png');
    
    }
  
    .delete-icon{
      background-image: url('../assets/images/icons/default_icons/icon-delete.png');
    
    }
  
    .flight-icon{
      background-image: url('../assets/images/icons/default_icons/icon-flight.png');
    
    }
  
    .train-icon{
      background-image: url('../assets/images/icons/default_icons/icon-train.png');
    
    }
  
    .car-icon{
      background-image: url('../assets/images/icons/default_icons/icon-car.png');
    
    }
  
    .travel-icon{
      background-image: url('../assets/images/icons/default_icons/icon-travel.png');
    
    }
  
    .icount-icon{
      background-image: url('../assets/images/icons/default_icons/icon-icount.png');
    
    }
  
    .parking-icon{
      background-image: url('../assets/images/icons/default_icons/icon-parking.png');
    
    }
  
    .buspass-icon{
      background-image: url('../assets/images/icons/default_icons/icon-buspass.png');
    
    }
  
    .search-range-icon{
      background-image: url('../assets/images/icons/default_icons/icon-search-range-2.png');
    
    }
  
    .tax-icon{
      background-image: url('../assets/images/icons/default_icons/icon-tax.png');
    
    }
    .policy-icon{
      background-image: url('../assets/images/icons/default_icons/icon-policy.png');
    
    }
  
    .pulse-icon{
      background-image: url('../assets/images/icons/default_icons/icon-pulse.png');
    
    }
    
    // .alert-icon{
    //   background-image: url('../assets/images/icons/default_icons/icon-alert.png');
    
    // }
    .error-big-icon{
      background-image: url('../assets/images/icons/default_icons/icon-error-big.png');
    
    }
  
    .info-big-icon{
      background-image: url('../assets/images/icons/default_icons/icon-info-big.png');
    
    }
    .tree-icon{
      background-image: url('../assets/images/icons/default_icons/icon_tree.png');
    }

    
  
  .sm-green-tick-icon{
  background-image: url('../assets/images/icons/default_icons/icon-process-complete-sm.png');
  }
  
  .sm-grey-tick-icon{
  background-image: url('../assets/images/icons/default_icons/icon-process-complete-grey-sm.png');
  }
  
  .green-tick-icon{
  background-image: url('../assets/images/icons/default_icons/icon-process-complete.png');
  }
  
  .sm-close-grey-icon{
  background-image: url('../assets/images/icons/default_icons/close-mini-grey.png');
  }
  
  .error-icon{
  background-image: url('../assets/images/icons/default_icons/icon-error-small.png');
  }
  
  
  .smiley1-yellow-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley1-selected.png');
  
  }
  
  .smiley2-yellow-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley2-selected.png');
  
  }
  
  
  .smiley3-yellow-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley3-selected.png');
  
  }
  
  .smiley4-yellow-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley4-selected.png');
  
  }
  
  .smiley5-yellow-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley5-selected.png');
  
  }
  
  
  
  .smiley1-grey-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley1-default.png');
  
  }
  
  .smiley2-grey-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley2-default.png');
  
  }
  
  
  .smiley3-grey-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley3-default.png');
  
  }
  
  .smiley4-grey-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley4-default.png');
  
  }
  
  .smiley5-grey-icon{
    background-image: url('../assets/images/icons/default_icons/icon-smiley5-default.png');
  
  }
  
  .tick-green-lg-icon{
  
    background-image: url('../assets/images/icons/default_icons/icon-Success-green.png')
  }
  .icon-select{
  
    background-image: url('../assets/images/icons/purple_icons/icon-select.png')
  }
  
  
  
  
  /*Blue Icons*/
  .icon-tick-dark.blue{
    background-image:url('../assets/images/icons/blue_icons/icon-tick-dark.png'); 
  }
  .tree-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon_tree.png');
  }
  
  .chat-icon.blue{
    background-image:url('../assets/images/icons/blue_icons/icon-chat.png'); 

  }
  .clapp-icon.blue{
    background-image:url('../assets/images/icons/blue_icons/icon-clapp.png'); 
  
  }
  .hand-icon.blue{
    background-image:url('../assets/images/icons/blue_icons/icon-hand.png'); 
  
  }
  .info-icon.blue{
    background-image:url('../assets/images/icons/blue_icons/icon-info-small.png'); 
  
  }
  
   
  
  .menu-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-menu.png');
  }
  
  .back-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-back.png');
  }
  .callback-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-callback.png');
  }
  
  .search-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon_search.png');
  }
  
  .close-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-close.png');
  }
  
  .faq-info-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-faq-info.png');
  
  }
  .alert-success-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-alert-success.png');
  
  }
  
  .alert-error-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-alert-error.png');
  
  }
  
  .filter-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-filter.png');
  
  }
  
  .copy-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-copy.png');
  
  }
  
  .location-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-location.png');
  
  }
  
  .star-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-star.png');
  
  }
  
  .previous-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-previous.png');
  
  }
  
  .next-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-next.png');
  
  }
  .down-arw-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-expand-arrow.png');
  
  }
  
  .up-arw-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-collapse-arrow.png');
  
  }
  
  .edit-icon.blue{
    background-image: url('../assets/images/icons/blue_icons/icon-edit.png');
  
  }
    .delete-sm-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-delete-mini.png');
    
    }
  
    .calendar-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-calendar.png');
    
    }
  
    .attachment-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-attachment.png');
    
    }
  
  
  
  
  
  
  
  
  
    .comment-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-comment.png');
    
    }
  
    .like-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-like.png');
    
    }
  
    .link-arw-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-link-arrow.png');
    
    }
  
    .home-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-home.png');
    
    }
  
    .notification-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-notification.png');
    
    }
  
    .profile-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-profile.png');
    
    }
  
    .services-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-services.png');
    
    }
  
     
  
    .v-menu-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-v-menu.png');
    
    }
  
    .mail-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-mail.png');
    
    }
  
    .call-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-call.png');
    
    }
  
    .download-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-download.png');
    
    }
  
    .upload-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-upload.png');
    
    }
  
    .inprogress-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-inprogress.png');
    
    }
  
  
  
    .mic-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-mic.png');
    
    }
    .hide-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-hide.png');
    
    }
  
    .show-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-show.png');
    
    }
     
  
    .dropdown-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-dropdown.png');
    
    }
  
    .thumb-up-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-thumb-up.png');
    
    }
    .graph-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-graph.png');
    
    }
  
    .announcement-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-announcement.png');
    
    }
    .group-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-group.png');
    
    }
  
  
    .id-badge-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-id-badge.png');
    
    }
  
    .emergency-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-emergency.png');
    
    }
  
    .personal-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-personal.png');
    
    }
  
    .address-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-address.png');
    
    }
  
    .i9-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-i9.png');
    
    }
  
    .ssn-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-ssn.png');
    
    }
  
    .bank-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-bank.png');
    
    }
  
    .corp-cc-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-corporate-cc.png');
    
    }
  
    
    .relocation-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-relocation.png');
    
    }
  
    .accomodation-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-accomodation.png');
    
    }
  
    .food-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-food.png');
    
    }
  
  
    .form-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-form.png');
    
    }
  
    .payroll-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-payroll.png');
    
    }
  
    .insurance-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-insurance.png');
    
    }
  
     
  
    .bills-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-bills.png');
    
    }
    .w4-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-w4.png');
    
    }
  
    .document-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-document.png');
    
    }
  
    
  
    .nomination-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-nomination.png');
    
    }
  
    
    .pan-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-pan.png');
    
    }
  
    .nsr-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-nsr.png');
    
    }
  
    .passport-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-passport.png');
    
    }
  
    .education-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-education.png');
    
    }
  
    .work-exp-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-work-exp.png');
    
    }
  
    .achivements-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-achivements.png');
    
    }
  
    .statutory-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-statutory.png');
    
    }
  
    .authentication-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-authentication.png');
    
    }
  
    .family-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-family.png');
    
    }
  
    .disability-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-disability.png');
    
    }
    
    .video-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-video.png');
    
    }
  
    .onboarding-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-onboarding.png');
    
    }
  
    .aadhar-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-aadhar.png');
    
    }
  
    .medical-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-medical.png');
    
    }
  
    .team-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-team.png');
    
    }
  
    .gallery-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-gallery.png');
    
    }
  
    .camera-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-camera.png');
    
    }
  
    .delete-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-delete.png');
    
    }
  
    .flight-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-flight.png');
    
    }
  
    .train-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-train.png');
    
    }
  
    .car-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-car.png');
    
    }
  
    .travel-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-travel.png');
    
    }
  
    .icount-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-icount.png');
    
    }
  
    .parking-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-parking.png');
    
    }
  
    .buspass-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-buspass.png');
    
    }
  
    .search-range-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-search-range-2.png');
    
    }
  
    .tax-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-tax.png');
    
    }
    .policy-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-policy.png');
    
    }
  
    .pulse-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-pulse.png');
    
    }
    .success-big-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-success-big.png');
    
    }
    // .alert-icon.blue{
    //   background-image: url('../assets/images/icons/blue_icons/icon-alert.png');
    
    // }
    .error-big-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-error-big.png');
    
    }
  
    .info-big-icon.blue{
      background-image: url('../assets/images/icons/blue_icons/icon-info-big.png');
    
    }
  
  
   
  /*Purple Icons*/ 
  .icon-tick-dark.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-tick-dark.png'); 
  }
  .icon-add.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-add-dark.png');
  }
  .tree-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon_tree.png');
  }
  .chat-icon.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-chat.png'); 
  
  }
  .clapp-icon.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-clapp.png'); 
  
  }
  .hand-icon.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-hand.png'); 
  
  }
  .info-icon.purple{
    background-image:url('../assets/images/icons/purple_icons/icon-info-small.png'); 
  
  }
  
   
  
  .menu-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-menu.png');
  }
  
  .back-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-back.png');
  }
  .callback-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-callback.png');
  }
  
  .search-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon_search.png');
  }
  
  .close-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-close.png');
  }
  
  .faq-info-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-faq-info.png');
  
  }
  .alert-success-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-alert-success.png');
  
  }
  
  .alert-error-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-alert-error.png');
  
  }
  
  .filter-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-filter.png');
  
  }
  
  .copy-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-copy.png');
  
  }
  
  .location-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-location.png');
  
  }
  
  .star-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-star.png');
  
  }
  
  .previous-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-previous.png');
  
  }
  
  .next-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-next.png');
  
  }
  .down-arw-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-expand-arrow.png');
  
  }
  
  .up-arw-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-collapse-arrow.png');
  
  }
  
  .edit-icon.purple{
    background-image: url('../assets/images/icons/purple_icons/icon-edit.png');
  
  }
    .delete-sm-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-delete-mini.png');
    
    }
  
    .calendar-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-calendar.png');
    
    }
  
    .attachment-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-attachment.png');
    
    }
  
  
  
  
  
  
  
  
  
    .comment-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-comment.png');
    
    }
  
    .like-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-like.png');
    
    }
  
    .link-arw-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-link-arrow.png');
    
    }
  
    .home-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-home.png');
    
    }
  
    .notification-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-notification.png');
    
    }
  
    .profile-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-profile.png');
    
    }
  
    .services-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-services.png');
    
    }
  
     
  
    .v-menu-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-v-menu.png');
    
    }
  
    .mail-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-mail.png');
    
    }
  
    .call-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-call.png');
    
    }
  
    .download-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-download.png');
    
    }
  
    .upload-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-upload.png');
    
    }
  
    .inprogress-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-inprogress.png');
    
    }
  
  
  
    .mic-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-mic.png');
    
    }
    .hide-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-hide.png');
    
    }
  
    .show-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-show.png');
    
    }
     
  
    .dropdown-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-dropdown.png');
    
    }
  
    .thumb-up-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-thumb-up.png');
    
    }
    .graph-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-graph.png');
    
    }
  
    .announcement-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-announcement.png');
    
    }
    .group-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-group.png');
    
    }
  
  
    .id-badge-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-id-badge.png');
    
    }
  
    .emergency-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-emergency.png');
    
    }
  
    .personal-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-personal.png');
    
    }
  
    .address-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-address.png');
    
    }
  
    .i9-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-i9.png');
    
    }
  
    .ssn-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-ssn.png');
    
    }
  
    .bank-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-bank.png');
    
    }
  
    .corp-cc-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-corporate-cc.png');
    
    }
  
    
    .relocation-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-relocation.png');
    
    }
  
    .accomodation-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-accomodation.png');
    
    }
  
    .food-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-food.png');
    
    }
  
  
    .form-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-form.png');
    
    }
  
    .payroll-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-payroll.png');
    
    }
  
    .insurance-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-insurance.png');
    
    }
  
     
  
    .bills-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-bills.png');
    
    }
    .w4-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-w4.png');
    
    }
  
    .document-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-document.png');
    
    }
  
    
  
    .nomination-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-nomination.png');
    
    }
  
    
    .pan-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-pan.png');
    
    }
  
    .nsr-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-nsr.png');
    
    }
  
    .passport-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-passport.png');
    
    }
  
    .education-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-education.png');
    
    }
  
    .work-exp-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-work-exp.png');
    
    }
  
    .achivements-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-achivements.png');
    
    }
  
    .statutory-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-statutory.png');
    
    }
  
    .authentication-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-authentication.png');
    
    }
  
    .family-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-family.png');
    
    }
  
    .disability-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-disability.png');
    
    }
    
    .video-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-video.png');
    
    }
  
    .onboarding-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-onboarding.png');
    
    }
  
    .aadhar-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-aadhar.png');
    
    }
  
    .medical-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-medical.png');
    
    }
  
    .team-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-team.png');
    
    }
  
    .gallery-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-gallery.png');
    
    }
  
    .camera-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-camera.png');
    
    }
  
    .delete-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-delete.png');
    
    }
  
    .flight-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-flight.png');
    
    }
  
    .train-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-train.png');
    
    }
  
    .car-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-car.png');
    
    }
  
    .travel-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-travel.png');
    
    }
  
    .icount-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-icount.png');
    
    }
  
    .parking-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-parking.png');
    
    }
  
    .buspass-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-buspass.png');
    
    }
  
    .search-range-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-search-range-2.png');
    
    }
  
    .tax-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-tax.png');
    
    }
    .policy-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-policy.png');
    
    }
  
    .pulse-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-pulse.png');
    
    }
    .success-big-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-success-big.png');
    
    }
    .alert-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-alert.png');
    
    }
    .error-big-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-error-big.png');
    
    }
  
    .info-big-icon.purple{
      background-image: url('../assets/images/icons/purple_icons/icon-info-big.png');
    
    }
  
  /* White Icons */
  .icon-tick-dark.white{
    background-image:url('../assets/images/icons/white_icons/icon-tick-dark.png'); 
  }
  .tree-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon_tree.png');
  }
  .chat-icon.white{
    background-image:url('../assets/images/icons/white_icons/icon-chat.png'); 
  
  }
  .clapp-icon.white{
    background-image:url('../assets/images/icons/white_icons/icon-clapp.png'); 
  
  }
  .hand-icon.white{
    background-image:url('../assets/images/icons/white_icons/icon-hand.png'); 
  
  }
  .info-icon.white{
    background-image:url('../assets/images/icons/white_icons/icon-info-small.png'); 
  
  }
  
   
  
  .menu-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-menu.png');
  }
  
  .back-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-back.png');
  }
  .callback-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-callback.png');
  }
  
  .search-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon_search.png');
  }
  
  .close-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-close.png');
  }
  
  .faq-info-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-faq-info.png');
  
  }
  .alert-success-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-alert-success.png');
  
  }
  
  .alert-error-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-alert-error.png');
  
  }
  
  .filter-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-filter.png');
  
  }
  
  .copy-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-copy.png');
  
  }
  
  .location-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-location.png');
  
  }
  
  .star-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-star.png');
  
  }
  
  .previous-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-previous.png');
  
  }
  
  .next-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-next.png');
  
  }
  .down-arw-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-expand-arrow.png');
  
  }
  
  .up-arw-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-collapse-arrow.png');
  
  }
  
  .edit-icon.white{
    background-image: url('../assets/images/icons/white_icons/icon-edit.png');
  
  }
    .delete-sm-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-delete-mini.png');
    
    }
  
    .calendar-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-calendar.png');
    
    }
  
    .attachment-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-attachment.png');
    
    }
  
  
  
  
  
  
    .addPlus-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-add-dark.png');
    
    }  
  
    .comment-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-comment.png');
    
    }
  
    .like-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-like.png');
    
    }
  
    .link-arw-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-link-arrow.png');
    
    }
  
    .home-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-home.png');
    
    }
  
    .notification-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-notification.png');
    
    }
  
    .profile-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-profile.png');
    
    }
  
    .services-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-services.png');
    
    }
  
     
  
    .v-menu-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-v-menu.png');
    
    }
  
    .mail-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-mail.png');
    
    }
  
    .call-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-call.png');
    
    }
  
    .download-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-download.png');
    
    }
  
    .upload-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-upload.png');
    
    }
  
    .inprogress-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-inprogress.png');
    
    }
  
  
  
    .mic-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-mic.png');
    
    }
    .hide-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-hide.png');
    
    }
  
    .show-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-show.png');
    
    }
     
  
    .dropdown-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-dropdown.png');
    
    }
  
    .thumb-up-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-thumb-up.png');
    
    }
    .graph-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-graph.png');
    
    }
  
    .announcement-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-announcement.png');
    
    }
    .group-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-group.png');
    
    }
  
  
    .id-badge-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-id-badge.png');
    
    }
  
    .emergency-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-emergency.png');
    
    }
    .address-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-address.png');
    
    }
  
    .i9-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-i9.png');
    
    }
  
    .ssn-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-ssn.png');
    
    }
  
    .bank-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-bank.png');
    
    }
  
    .corp-cc-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-corporate-cc.png');
    
    }
  
    
    .relocation-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-relocation.png');
    
    }
  
    .accomodation-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-accomodation.png');
    
    }
  
    .food-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-food.png');
    
    }
  
  
    .form-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-form.png');
    
    }
  
    .payroll-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-payroll.png');
    
    }
  
    .insurance-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-insurance.png');
    
    }
  
     
  
    .bills-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-bills.png');
    
    }
    .w4-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-w4.png');
    
    }
  
    .document-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-document.png');
    
    }
  
    
  
    .nomination-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-nomination.png');
    
    }
  
    
    .pan-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-pan.png');
    
    }
  
    .nsr-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-nsr.png');
    
    }
  
    .passport-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-passport.png');
    
    }
  
    .education-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-education.png');
    
    }
  
    .work-exp-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-work-exp.png');
    
    }
  
    .achivements-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-achivements.png');
    
    }
  
    .statutory-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-statutory.png');
    
    }
  
    .authentication-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-authentication.png');
    
    }
  
    .family-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-family.png');
    
    }
  
    .disability-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-disability.png');
    
    }
    
    .video-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-video.png');
    
    }
  
    .onboarding-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-onboarding.png');
    
    }
  
    .aadhar-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-aadhar.png');
    
    }
  
    .medical-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-medical.png');
    
    }
  
    .team-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-team.png');
    
    }
  
    .gallery-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-gallery.png');
    
    }
  
    .camera-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-camera.png');
    
    }
  
    .delete-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-delete.png');
    
    }
  
    .flight-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-flight.png');
    
    }
  
    .train-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-train.png');
    
    }
  
    .car-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-car.png');
    
    }
  
    .travel-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-travel.png');
    
    }
  
    .icount-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-icount.png');
    
    }
  
    .parking-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-parking.png');
    
    }
  
    .buspass-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-buspass.png');
    
    }
  
    .search-range-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-search-range-2.png');
    
    }
  
    .tax-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-tax.png');
    
    }
    .policy-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-policy.png');
    
    }
  
    .pulse-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-pulse.png');
    
    }
    .success-big-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-success-big.png');
    
    }
    .alert-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-alert-white.png');
    
    }
    .error-big-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-error-big.png');
    
    }
  
    .info-big-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-info-big.png');
    
    }
  
  /*Orange Icons*/
  .icon-tick-dark.orange{
    background-image:url('../assets/images/icons/orange_icons/icon-tick-dark.png'); 
  }
  .tree-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon_tree.png');
  }
  .chat-icon.orange{
    background-image:url('../assets/images/icons/orange_icons/icon-chat.png'); 
  
  }
  .clapp-icon.orange{
    background-image:url('../assets/images/icons/orange_icons/icon-clapp.png'); 
  
  }
  .hand-icon.orange{
    background-image:url('../assets/images/icons/orange_icons/icon-hand.png'); 
  
  }
  .info-icon.orange{
    background-image:url('../assets/images/icons/orange_icons/icon-info-small.png'); 
  
  }
  
   
  
  .menu-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-menu.png');
  }
  
  .back-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-back.png');
  }
  .callback-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-callback.png');
  }
  
  .search-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon_search.png');
  }
  
  .close-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-close.png');
  }
  
  .faq-info-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-faq-info.png');
  
  }
  .alert-success-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-alert-success.png');
  
  }
  
  .alert-error-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-alert-error.png');
  
  }
  
  .filter-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-filter.png');
  
  }
  
  .copy-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-copy.png');
  
  }
  
  .location-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-location.png');
  
  }
  
  .star-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-star.png');
  
  }
  
  .previous-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-previous.png');
  
  }
  
  .next-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-next.png');
  
  }
  .down-arw-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-expand-arrow.png');
  
  }
  
  .up-arw-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-collapse-arrow.png');
  
  }
  
  .edit-icon.orange{
    background-image: url('../assets/images/icons/orange_icons/icon-edit.png');
  
  }
    .delete-sm-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-delete-mini.png');
    
    }
  
    .calendar-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-calendar.png');
    
    }
  
    .attachment-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-attachment.png');
    
    }
  
  
  
  
  
  
  
  
  
    .comment-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-comment.png');
    
    }
  
    .like-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-like.png');
    
    }
  
    .link-arw-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-link-arrow.png');
    
    }
  
    .home-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-home.png');
    
    }
  
    .notification-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-notification.png');
    
    }
  
    .profile-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-profile.png');
    
    }
  
    .services-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-services.png');
    
    }
  
     
  
    .v-menu-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-v-menu.png');
    
    }
  
    .mail-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-mail.png');
    
    }
  
    .call-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-call.png');
    
    }
  
    .download-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-download.png');
    
    }
  
    .upload-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-upload.png');
    
    }
  
    .inprogress-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-inprogress.png');
    
    }
  
  
  
    .mic-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-mic.png');
    
    }
    .hide-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-hide.png');
    
    }
  
    .show-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-show.png');
    
    }
     
  
    .dropdown-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-dropdown.png');
    
    }
  
    .thumb-up-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-thumb-up.png');
    
    }
    .graph-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-graph.png');
    
    }
  
    .announcement-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-announcement.png');
    
    }
    .group-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-group.png');
    
    }
  
  
    .id-badge-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-id-badge.png');
    
    }
  
    .emergency-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-emergency.png');
    
    }
  
    .personal-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-personal.png');
    
    }
  
    .address-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-address.png');
    
    }
  
    .i9-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-i9.png');
    
    }
  
    .ssn-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-ssn.png');
    
    }
  
    .bank-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-bank.png');
    
    }
  
    .corp-cc-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-corporate-cc.png');
    
    }
  
    
    .relocation-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-relocation.png');
    
    }
  
    .accomodation-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-accomodation.png');
    
    }
  
    .food-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-food.png');
    
    }
  
  
    .form-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-form.png');
    
    }
  
    .payroll-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-payroll.png');
    
    }
  
    .insurance-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-insurance.png');
    
    }
  
     
  
    .bills-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-bills.png');
    
    }
    .w4-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-w4.png');
    
    }
  
    .document-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-document.png');
    
    }
  
    
  
    .nomination-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-nomination.png');
    
    }
  
    
    .pan-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-pan.png');
    
    }
  
    .nsr-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-nsr.png');
    
    }
  
    .passport-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-passport.png');
    
    }
  
    .education-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-education.png');
    
    }
  
    .work-exp-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-work-exp.png');
    
    }
  
    .achivements-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-achivements.png');
    
    }
  
    .statutory-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-statutory.png');
    
    }
  
    .authentication-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-authentication.png');
    
    }
  
    .family-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-family.png');
    
    }
  
    .disability-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-disability.png');
    
    }
    
    .video-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-video.png');
    
    }
  
    .onboarding-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-onboarding.png');
    
    }
  
    .aadhar-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-aadhar.png');
    
    }
  
    .medical-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-medical.png');
    
    }
  
    .team-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-team.png');
    
    }
  
    .gallery-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-gallery.png');
    
    }
  
    .camera-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-camera.png');
    
    }
  
    .delete-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-delete.png');
    
    }
  
    .flight-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-flight.png');
    
    }
  
    .train-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-train.png');
    
    }
  
    .car-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-car.png');
    
    }
  
    .travel-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-travel.png');
    
    }
  
    .icount-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-icount.png');
    
    }
  
    .parking-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-parking.png');
    
    }
  
    .buspass-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-buspass.png');
    
    }
  
    .search-range-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-search-range-2.png');
    
    }
  
    .tax-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-tax.png');
    
    }
    .policy-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-policy.png');
    
    }
  
    .pulse-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-pulse.png');
    
    }
    .success-big-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-success-big.png');
    
    }
    .alert-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-alert.png');
    
    }
    .error-big-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-error-big.png');
    
    }
  
    .info-big-icon.orange{
      background-image: url('../assets/images/icons/orange_icons/icon-info-big.png');
    
    }

    .dynamic-icon{

        background-image:url('../assets/images/icons/dynamic-icon.png')
    }

    .innovative-icon{

        background-image:url('../assets/images/icons/innovative-icon.png')
    }

    
    .smart-work-icon{

        background-image:url('../assets/images/icons/smart-work-icon.png')
    }

    

    .icon_smiley.selected .default,  .icon_smiley .yellow{
      display: none;
    }
    .icon_smiley.selected .yellow{
      display: block;
    }

    .success-big-icon.green{
      background-image: url('../assets/images/icons/green_icons/icon-success-big.png');    
    }
    .error-big-icon.red{
      background-image: url('../assets/images/icons/red_icons/icon-error-big.png');
    
    }
    .success-big-icon{
      background-image: url('../assets/images/icons/default_icons/icon-success-big.png');    
    } 
    .success-big-icon.lightIcon{
      background-image: url('../assets/images/icons/default_icons/icon-success-bigGrey.png');    
    } 
    .forward-icon{
      background-image: url('../assets/images/icons/default_icons/icon-forward.png');    
    }
    .forward-icon.white{
      background-image: url('../assets/images/icons/white_icons/icon-forward.png');    
    }
    .endorsement-icon{
      background-image: url('../assets/images/icons/default_icons/icon-endorsement.png');
    }
    .endorsement-icon.purple, .endorsement-icon.active{
      background-image: url('../assets/images/icons/purple_icons/icon-endorsement.png');
    }


/*icon round*/






.DSA_tabIcon-Circle{
  @include icon-circle;
  background: transparent;
  border: 1px solid $wb-bgcolor-white;
}  


.circle24{
  width:24px;
  height:24px;
}
.circle24 .icon{
margin-top: -4px;
}
  
/*logo*/   


/* Icon Styles Begin */

.icon-list.doc_icon-list{
  li{
    width: calc(100%/8);
    min-height: 85px;   
    background: $wb-bgcolor-white;         
    .icon_name{
      color: $wb-iconcolor-black;
      margin: 8px 0 0;
      font-size: $text-sm;
      font-weight: $wb-text-regular;   
      font-family: $wb-font-style-1;
      font-style: normal;
    }
    .html_code,.html_unicode{
      color: $wb-iconcolor-black;
      margin: 8px 0 0;
      font-size: $text-md;
      font-weight: $wb-text-regular;   
      font-family: $wb-font-style-1;
      font-style: normal;
    }
   
    
  }
}
.iconScrollLi {
  position: relative;
}
.iconCodeview{
  display: none;
  position: absolute;
  left:0;
  right:0;
  padding:8px 8px 16px 8px;
  bottom:100%;
  z-index: 2;
  background: $wb-bgcolor-white;
  @extend %raised-shadow;
  margin:4px;
}
.iconCodeview:after,.iconCodeview:before{
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent; 
  position: absolute;
  bottom:-8px;
  left:46%; 
  content:'';
}
.iconCodeview:after{
  border-top: 10px solid $wb-bgcolor-white;
}
.iconCodeview:before{
  border-top: 10px solid $wb-bggrey-lighter;
  bottom:-10px;
}
.icon-list li.active{
  border:1px solid $wb-bgcolor-dark;
  
  
}
.icon-list li.active .iconCodeview{
  display: block;
}
@media screen and (max-width:1366px){
  .icon-list.doc_icon-list{
    li{
      width: calc(100%/6);
    }
  }
}





.icon.blue{
  color: $wb-iconcolor-blue; 
}

.icon.purple{
  color: $wb-iconcolor-dark; 
}

.icon.white{
  color: $wb-iconcolor-white; 
}

.icon.red{
  color: $wb-iconcolor-red; 
}
.icon.yellow{
  color: $wb-iconcolor-yellow; 
}
.icon.lightyellow{
  color: $wb-iconcolor-yellow; 
}


.icon.black{
  color: $wb-iconcolor-black;    
}

.icon.dark-purple{
  color: $wb-iconcolor-thick; 
}



.icon.green{
  color: $wb-iconcolor-green;
}  


/*icon position*/


.x-20{
  width: 20px;
  height: 20px; 
  font-size: 20px; 
}


.x-32{
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.x-48{
  width: 48px;
  height: 48px;
  font-size: 48px;
}


.x-64{
  width: 64px;
  height: 64px;
  font-size: 64px;  
}


a.icon:hover, a:hover{
  text-decoration: none;
}

.icon.icon-close-mini{
    font-size: 10px;
    padding: 3px;
}
.icon-process-complete{    
  color: $wb-iconcolor-green;
}
.icon-inprogressBlue{   
  color: $wb-iconcolor-blue;
}
.icon-pending{  
  color: $wb-iconcolor-yellow;
}
.icon-rejected{  
  color: $wb-icongrey-midlight;
}  
 

.smiley1-yellow-icon,
.smiley2-yellow-icon,
.smiley3-yellow-icon,
.smiley4-yellow-icon,
.smiley5-yellow-icon{
  color: $wb-iconcolor-yellow; 
}

.smiley1-grey-icon,
.smiley2-grey-icon,
.smiley3-grey-icon,
.smiley4-grey-icon,
.smiley5-grey-icon{
  color: $wb-icongrey-midlight;  
}

.icon_rating.selected .default,  .icon_rating .purple{
  display: none;
}

.icon_rating.selected .purple{
  display: block;
}