.DSA_wb_displayText{
    font-size: $size-hd-48;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h1Header{
    font-size: $size-hd-24;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h2Header{
    font-size: $size-hd-18;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h3Header{
    font-size: $size-hd-16;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h4Header{
    font-size: $size-hd-14;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h4HeaderRegular{
    font-size: $size-hd-14;
    font-weight: $font-400;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h5Header{
    font-size: $size-hd-12;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_h5HeaderCaps{
    font-size: $size-hd-12;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
    text-transform: uppercase;
}
.DSA_wb_h2HeaderRegular{
    font-size: $size-hd-18;
    font-weight: $font-400;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_mainBodyTxt{
    font-size: $size-text-16;
    font-weight: $font-400;
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_mainBodyTxtSM{
    font-size: $size-text-16;
    font-weight: $font-500;
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_mainBodyTxt2{
    font-size: $size-text-14;
    font-weight: $font-400;
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_mainBodyTxt2SM{
    font-size: $size-text-14;
    font-weight: $font-500;
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_caption{
    font-size: $size-text-12;
    font-weight: $font-400;   
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_overline{
    font-size: $size-text-10;
    font-weight: $font-600;
    /* font-family: $font-style-1; */
    font-family: $font-style-2; // As per new Design
    text-transform : uppercase;
    font-style: normal;
}
.DSA_wb_mainBodyTxtXS{
    font-size: $size-text-10;
    font-weight: $font-400;
    font-family: $font-style-1;    
    font-style: normal;
}
.DSA_wb_Btn_Big{
    font-size: $size-button-18;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_Btn_smallSM{
    font-size: $size-button-14;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_Btn_smaller{
    font-size: $size-button-12;
    font-weight: $font-600;
    font-family: $font-style-2;
    font-style: normal;
}
.DSA_wb_Btn_normal{
    font-size: $size-button-16;
    font-weight: $font-500;
    font-family: $font-style-1;
    font-style: normal;
}
.DSA_wb_Btn_small{
    font-size: $size-button-14;
    font-weight: $font-400;
    font-family: $font-style-1;
    font-style: normal;
} 